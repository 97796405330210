.MuiTableContainer-root
  @media (max-width: 768px)
    max-width: calc(100vw - 30px)

.MuiTable-root
  border: 1px solid #E5E5E5
  overflow-x: auto
  font-size: 12px !important
  word-break: break-word

  @media (max-width: 768px)
    word-break: normal

  .MuiTableRow-head
    border-bottom: 1px solid #E5E5E5

  .MuiTableBody-root .MuiTableRow-root:nth-child(odd)
    background: #f4f4f4

  .MuiTableBody-root .row-detail-open
    background: #fff !important
    &:nth-child(4n-3)
      background: #f4f4f4 !important

  .MuiTableCell-root
    border: none
    font-family: HelveticaNeue-Regular, sans-seri !important
    font-size: 12px !important
    padding: 7px 5px !important

.MuiTableContainer-root
  position: relative

  .panel
    position: absolute
    left: 0
    top: 0
    z-index: 10
    width: 100%
    height: 100%
    background: #fff
    display: flex
    justify-content: center
    align-items: center

  .no-data-message
    padding: 2px 0
    text-align: center
    border: 1px solid #E5E5E5
    border-top: none
    display: flex
    background: #f4f4f4
    min-height: 35px

    > p
      margin-top: 10px

  .detail-open
    width: 10px
    padding: 0 5px

    > div
      min-width: 38px
      display: flex
      justify-content: center
      cursor: pointer

  .detail-cell
    padding: 0 !important

.pagination
  display: flex
  justify-content: space-between
  border: 1px solid #E5E5E5
  padding: 3px
  width: 100%

  @media (max-width: 768px)
    max-width: calc(100vw - 30px)

  > button
    background: rgba(0,0,0,.1)
    border: none !important

    &:disabled
      opacity: 0.4

    &:last-child
      margin-left: auto

    @media (max-width: 768px)
      width: 100px !important
      min-width: 100px !important

.panel-transition-enter 
  opacity: 0

.panel-transition-enter-active 
  opacity: 0.8
  transition: opacity 300ms

.panel-transition-exit 
  opacity: 0.8

.panel-transition-exit-active 
  opacity: 0
  transition: opacity 300ms
