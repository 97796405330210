@import 'utils/_mixins'
@import 'utils/_variables'

// CSS Reset
html, body, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, input, select, textarea, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video
  background: transparent
  box-sizing: border-box
  border: 0
  color: $light-black
  font: 10px $helvetica
  margin: 0
  padding: 0
  vertical-align: baseline

html
  font-size: 16px
  scroll-behavior: smooth

button
  background: transparent

a
  text-decoration: none

button, a
  +transition(.33s)

// Fonts
@font-face
  font-family: $helvetica-name
  src: url('../fonts/helveticaneue.woff') format('woff'), url('../fonts/helveticaneue.ttf') format('truetype')

@font-face
  font-family: $helvetica-medium-name
  src: url('../fonts/helveticaneuemedium.woff') format('woff'), url('../fonts/helveticaneuemedium.ttf') format('truetype')

// Helpers
.disable-pointer-events
  pointer-events: none

.display-flex
  display: flex

.text-center
  text-align: center

.text-error
  color: $input-error

#root > section
  display: flex
  flex-wrap: wrap
  margin-top: $header-height + 15
  padding: 0 15px 20px

// Menu & Pages
#page-wrapper
  flex: 1 0 100%
  padding-left: 270px

  @media (max-width: 768px)
    padding-left: 0

// Dialog
[data-reach-dialog-overlay]
  z-index: 100