@import '../../general/sass/utils/_variables'

.input-auto-complete-wrapper
  background: #fff
  border-left: 1px solid $light-grey
  border-right: 1px solid $light-grey
  box-shadow: 0 1px 5px rgba(0, 0, 0, .55)
  left: 0
  position: absolute
  right: 0
  z-index: 2

  button
    display: flex
    border: 0
    border-bottom: 1px solid $light-grey
    border-radius: 0
    display: block
    font-size: 14px
    margin: 0
    min-width: 0 !important
    max-width: 100% !important
    width: 100%
    padding: 8px 10px
    text-align: left
    &:hover
      background: $green
