#page-subscription-order-browse
  width: 100%
  form
    justify-content: flex-start
    > div
      padding-right: 20px
      max-width: 200px
      label
        margin-top: 0
    button.search
      position: relative

    @media (max-width: 768px)
      flex-direction: column
      > div
        padding: 0
        max-width: 100% !important
        width: 100%
        > label
          margin: 0
      button.search
        position: relative
        margin-bottom: 30px
        margin-left: 50%

  .expandable-section
    padding: 20px

    h6
      flex: 0 1 100%
      border: none
      margin-bottom: 4px

    *
      color: #777

    .page-subscription-order-browse
      max-width: 100%

  .material-icons
    vertical-align: super
    font-size: 1.2rem

  .search-tooltip
    span.multi-line
      color: unset
      font-size: 13px
      text-align: left
      max-width: 560px

  .renew-button
    display: flex
    justify-content: center

  pre 
    font-size: 12px
    line-height: 20px