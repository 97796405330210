@import '../../general/sass/utils/_variables'

#admintools-account-purge
  .input-select
    margin-top: 0

#admintools-account-purge
  form > div
    margin-top: 15px
    width: 100%

#admintools-account-purge > p
  margin-bottom: 15px
