#page-country-add-edit
  h5
    display: inline-flex
    border: none
    margin: 20px 0 0 0
    flex: 0 1 100%

  form p.bolder.warning
    margin-bottom: 25px

  p.error-message ~ label
    margin-bottom: 40px
    margin-top: -2px

  form
    @media (max-width: 768px)
      display: flex
      flex-direction: column

      > div, button
        width: 100%
        max-width: 100%
        margin: 0

      > p:first-child
        order: 1

      > p:nth-child(2)
        order: 2

      > div:nth-child(3)
        order: 4

      > div:nth-child(4)
        order: 3
