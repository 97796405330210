@import '../general/sass/utils/_mixins'
@import '../general/sass/utils/_variables'

.mobilebar-panel
  position: fixed
  left: 0
  top: 0
  height: 100vh
  width: 100vw
  z-index: 11
  opacity: 0.6
  background: #aaa
  @media (min-width: 768px)
    display: none

.mobilebar-wrapper
  position: fixed
  top: 0
  left: 0
  height: 100vh
  width: 255px
  z-index: 12
  overflow-y: auto
  user-select: none
  display: flex
  flex-direction: column
  background: #D5FFB2
  transform: translate(-255px)
  transition: transform 0.2s ease-in-out
  @media (min-width: 768px)
    display: none

  nav
    flex: 1

    a
      font-size: 16px
      color: #333
      display: flex
      padding: 15px 20px
      cursor: pointer

      &:hover, &.active
        background: $green
        color: white

    .mobilebar-expander-primary
      display: flex
      justify-content: space-between
      font-size: 16px
      color: #333
      padding: 15px 20px
      cursor: pointer

    .mobilebar-group-primary
      background: #537A5A
      transition: all 0.3s ease-in-out
      opacity: 0
      max-height: 0
      visibility: hidden
      overflow: hidden

      &>a
        padding-left: 50px
        color: #fff

        &:hover, &.active
          background: $green
          color: white

      .mobilebar-expander-secondary
        display: flex
        justify-content: space-between
        font-size: 16px
        color: #fff
        padding: 15px 20px 15px 50px
        cursor: pointer

      .mobilebar-group-secondary
        transition: all 0.3s ease-in-out
        opacity: 0
        max-height: 0
        visibility: hidden
        overflow: hidden

        &>a
          padding-left: 80px
          background: #284e30
          color: #fff

          &:hover, &.active
            background: $green
            color: white

    .expand-mobilebar
      max-height: 1000px !important
      opacity: 100 !important
      visibility: visible !important

    .open-arrow
      transform: rotate(180deg)

  img
    width: 20px
    margin-right: 20px

  svg
    margin-top: 2px

  &::-webkit-scrollbar
    width: 5px

  /* Handle */
  &::-webkit-scrollbar-thumb
    background: $green
    border-radius: 3px

  .logout
    font-size: 16px
    color: white
    display: flex
    padding: 15px 20px
    cursor: pointer
    background: #333

    &:hover
      opacity: 0.9

  .mobilebar-close
    display: flex
    justify-content: flex-end
    flex: 1
    padding: 15px
    cursor: pointer

.show-mobilebar
  transform: translate(0)
