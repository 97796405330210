// Colors
$dark-green: #4F9316
$green: #6ab42d
$light-green: #d5ffb2
$dark-grey: #666
$light-grey: #b5b5b5
$light-black: #333
$dark-orange: #FF7900
$red: #f00
$input-error: #f00

// Fonts
$helvetica-name: 'HelveticaNeue-Regular'
$helvetica: $helvetica-name, sans-serif
$helvetica-medium-name: 'HelveticaNeue-Medium'
$helvetica-medium: $helvetica-medium-name, sans-serif

// Sizes
$header-height: 50px
$page-padding: 12px 15px 15px
