@import '../general/sass/utils/_variables'

#page-installations
  width: 100%
  form
    margin-top: 25px
    justify-content: flex-start
    flex-wrap: nowrap
    > div
      padding-right: 20px
      max-width: 200px
      label
        margin-top: 0
    > div:nth-child(3)
      min-width: 175px
    button.search
      position: relative

    @media (max-width: 768px)
      flex-direction: column
      > div
        padding: 0
        max-width: 100% !important
        width: 100%
        > label
          margin: 0
      button.search
        position: relative
        margin-bottom: 30px
        margin-left: 50%

  .sensor-status-section
    display: flex
    justify-content: center
    align-items: center

    .sensor-status
      border-radius: 50%
      height: 16px
      margin: -2px 8px 0 0
      width: 16px
      &.CONNECTED
        background: $green
      &.DISCONNECTED
        background: $red
      &.NEVER_CONNECTED
        background: $dark-grey

  .ReactTable
    .-loading.-active img
      width: 25px
    .-pageInfo
      display: none

    .rt-tr-group
      .expandable-section
        padding: 20px

        h6
          flex: 0 1 100%
          border: none
          margin-bottom: 4px

        *
          color: #777

    .tpi-access-info
      padding: 20px

      h5,h6
        flex: 0 1 100%
        border: none
      h5
        margin-bottom: 20px
      h6
        margin-bottom: 10px
        button
          margin-left: 5px
      > div > div
        display: flex
        flex: 0 1 100%
        & p
          padding: 4px 0px
          flex: 0 1 100%
        & p:first-of-type
          flex: 0 1 10%
      h6:last-of-type
        margin-top: 20px

    .center
      text-align: center

    @media (max-width: 768px)
      max-width: calc(100vw - 30px)
      .rt-table
        overflow-x: auto
      .-pagination
        > div > button
          min-width: 0
