.payment-currency-wrapper
  max-width: 400px
  position: relative
  label:last-child
    bottom: 0
    margin: 0
    position: absolute
    right: 0

#page-subscription-order-add
  > p.highlight
    margin-bottom: 25px
  label
    margin-top: 0
  .start-date
    margin-bottom: 20px
  > div
    > div:nth-child(2), > div:nth-child(3)
      width: 47%
      @media (max-width: 768px)
        width: 100%

  @media screen and (min-width: 700px)
    .negative-top
      margin-top: -130px

  @media (max-width: 768px)
    > div
      display: flex
      flex-direction: column

      button
        width: 100%
        max-width: 100%

  .forms-wrapper
    display: flex
    justify-content: space-between
    flex-wrap: wrap

    > form
      width: 47%
      > div
        width: 100%

    @media (max-width: 768px)
      width: 100%

      > form
        width: 100%

      > form:nth-child(2)
        order: -1

  button.unstyled
    font-size: 12px
    max-width: none
    text-align: left
    &::before
      background: url(../../../general/img/cloud-download.svg)
      content: ''
      display: inline-block
      height: 24px
      margin: 0 5px 1px 0
      vertical-align: middle
      width: 24px
    &.loading
      pointer-events: none
      &::before
        background: url(../../../general/img/loading.svg)
        background-size: contain
