#page-summary
  > section
    display: flex
    flex-wrap: nowrap
    justify-content: space-between
    @media (max-width: 768px)
      flex-wrap: wrap

    &.col-4 > div
      flex: 1 1 24%
      max-width: 24%

    > div
      flex: 1 1 19%
      max-width: 19%
      margin: 0 10px

      label
        max-width: none
        width: 100%

    @media (max-width: 768px)
      flex-direction: column
      > div
        padding: 0
        margin: 0
        max-width: 100% !important
        > label
          margin: 0
      button.search
        position: relative
        margin-bottom: 30px
        margin-left: 50%

  > img
    display: block
    margin: 50px auto 0

  .chart-wrapper
    font-size: 16px
    > .recharts-wrapper
      margin: 0 auto
