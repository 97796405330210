@import '../general/sass/utils/_variables'

#page-resellers-building-monitoring,
#page-resellers-crm-access,
#page-users
  width: 100%

  .btn-actions, .enable-disable-btns
    button
      font-size: 14px
      min-width: 80px
      &::after
        height: 22px
        margin-left: 3px
        margin-top: -11px
        width: 22px

  form
    justify-content: flex-start

    > div
      max-width: 300px

    label
      max-width: 95%

    .btn-actions
      width: 30%
      button
        margin-top: 7px

      @media (max-width: 768px)
        width: 100%
        max-width: 100%

        button
          width: 100%
          max-width: 100%

    @media (max-width: 768px)
      display: flex
      > div:first-child
        flex: 1
        > label
          max-width: 100%

  .enable-disable-btns
    margin-bottom: 15px
    button
      min-width: 120px
      &:first-child
        margin-right: 10px

  .disable-btns button
    pointer-events: none

  &.without-stock-menu .ReactTable
    width: 100%

#page-resellers-building-monitoring,
#page-resellers-crm-access
  &.without-stock-menu form
    > div
      max-width: 450px
      width: 80%
    .btn-actions
      max-width: 150px
      width: 20%

#page-users
  form > div
    max-width: none
    width: 100%
    label, .input-select
      margin-bottom: 5px
      margin-top: 0
    input[type="text"]
      max-width: 500px
  .btn-actions
    margin: 0 0 25px

  .input-select
    margin-top: 0
    max-width: none
    padding-top: 0
