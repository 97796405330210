@import '../general/sass/utils/_variables'

#page-setup-smart-meters
  width: 100%

  > div
    display: flex

    button
      width: 100%
      min-width: 100%

    form
      display: flex
      flex-direction: column
      margin-right: 240px
      width: 400px

      >div, input
        width: 100%

      button
        min-height: 40px

      @media (max-width: 768px)
        width: 100%
        margin-right: 0

        > div, > button
          width: 100%
          max-width: 100%
          margin: 0

    .file-example
      @media (max-width: 768px)
        display: none

  .add-integrations-text
    margin: 20px 0 10px 0

  .add-integrations-button
    @media (max-width: 768px)
      width: 100%
      max-width: 100%
