#page-subscription-add
  width: 100%
  form
    @media (max-width: 768px)
      > div, > button
        width: 100%
        max-width: 100%

#page-subscription-order-add
  width: 100%
  input[name="paymentRate"]::-webkit-outer-spin-button,
  input[name="paymentRate"]::-webkit-inner-spin-button,
  input[name="hardwareUnitPrice"]::-webkit-outer-spin-button,
  input[name="hardwareUnitPrice"]::-webkit-inner-spin-button
    -webkit-appearance: none
    margin: 0

  .payment-currency-wrapper
    max-width: 400px
    position: relative
    label:last-child
      bottom: 0
      margin: 0
      position: absolute
      right: 0
