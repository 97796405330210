@import '../../general/sass/utils/_variables'
@import '../../general/sass/utils/_mixins'

#page-resellers-edit
  width: 100%

  nav
    display: flex
    flex-wrap: wrap
    margin-bottom: 20px

    div
      cursor: pointer
      background: $dark-grey
      color: #fff
      display: inline-block
      font-size: 14px
      padding: 10px 20px
      +transition(.33s)

      &:hover
        background: $green
      &.active
        background: $light-green
        color: $light-black
        pointer-events: none

      @media (max-width: 768px)
        flex: 1
        display: flex
        align-items: center
        justify-content: center
        text-align: center

  form
    display: flex
    flex-direction: column

    > button
      min-height: 40px

    @media (max-width: 768px)
      width: 100%

      > div, > button
        width: 100%
        max-width: 100%
        margin: 0

  #integrations
    > .input-select:first-child
      padding-top: 0

    .tooltip-element-container
      font-size: inherit
      color: #ffffff
