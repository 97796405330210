#page-stock-block-browse
  width: 100%
  .filters
    display: flex
    width: 100%

    label
      margin: 0 20px 20px 0

    label:nth-of-type(1)
      min-width: 200px

    @media (max-width: 768px)
      flex-direction: column
      margin-bottom: 30px
      > label
        width: 100%
        margin: 0
      button.search
        position: relative
        margin-left: 50%

  .ReactTable
    .-loading.-active img
      width: 25px
    .-pageInfo
      display: none

    .tpi-access-info
      padding: 20px

      h5,h6
        flex: 0 1 100%
        border: none
      h5
        margin-bottom: 20px
      h6
        margin-bottom: 10px
        button
          margin-left: 5px
      > div > div
        display: flex
        flex: 0 1 100%
        & p
          padding: 4px 0px
          flex: 0 1 100%
        & p:first-of-type
          flex: 0 1 10%
      h6:last-of-type
        margin-top: 20px

    @media (max-width: 768px)
      max-width: calc(100vw - 30px)
      .rt-table
        overflow-x: auto
      .-pagination
        > div > button
          min-width: 0
