div.select-locale-timezone
  div.input-with-loading
    display: flex

    label
      flex-grow: 1
      margin-top: 0px

    img
      transform: scale(0.8)
      padding-bottom: 4px
