@import '../../general/sass/utils/_variables'

#admintools-delete-sensor-events
  .input-select
    margin-top: 0

  .react-datepicker-wrapper,
  .react-datepicker__input-container
    display: block

#admintools-delete-sensor-events
  form > div:not(:last-child)
    margin-top: 15px
    width: 100%

#admintools-delete-sensor-events > p
  margin-bottom: 15px
