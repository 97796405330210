div.field-with-tooltip
  cursor: default

  > div.tooltip
    position: fixed

    flex-direction: column

    background: #333
    color: white
    text-align: center
    font-size: 0.7rem
    border-radius: 3px

    box-sizing: border-box
    padding: 10px
    height: 40px
    width: 140px

    opacity: 0.9

    display: none
    z-index: 2

  > div.tooltip-show
    display: flex
    justify-content: center
    align-items: center

div.field-with-tooltip.input-select-component
  > *
    pointer-events: none

div.field-with-tooltip-card
  box-sizing: border-box
  line-height: 5px
  padding: 5px
  margin-top: -5px
  pointer-events: all

  > *
    pointer-events: none
