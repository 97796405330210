@import '../../general/sass/utils/_variables'

.btn-actions > a, button:not(.react-datepicker__navigation)
  margin-right: 25px
  max-width: 280px
  &:last-child
    margin-right: 0

.btn-actions
  @media (max-width: 768px)
    display: flex
    margin-bottom: 10px

    button
      min-width: 20vw !important

#client-details
  h4
    padding-top: 0

  nav
    display: flex
    flex-wrap: wrap
    a
      background: $dark-grey
      color: #fff
      display: inline-block
      font-size: 14px
      padding: 10px 20px
      &:hover
        background: $green
      &.active
        background: $light-green
        color: $light-black
        pointer-events: none

      @media (max-width: 768px)
        flex: 1
        display: flex
        align-items: center
        justify-content: center
        text-align: center

#client-details-columns
  > div
    border: 1px solid $light-grey
    display: flex
    flex-wrap: wrap
    justify-content: space-between
    margin-top: 25px
    padding: $page-padding
    > div, > form, > h6
      flex: 0 1 100%
      &.xs-4
        flex-basis: 30%

    > div, > form
      h6
        margin-bottom: 10px
      label
        margin-top: 0

    @media (max-width: 768px)
      flex-direction: column

#user-details > p
  margin-bottom: 25px

#user-details,
#sensor-status
  div, h6
    width: 100%

  #smart-meter-app-email
    display: flex
    > p
      margin-right: 10px

    > div
      width: auto

    @media (max-width: 768px)
      margin-bottom: 15px

#user-details form .btn-actions button
  min-width: 125px

#account-details,#sensor-status,#tariff
  > div
    display: flex
    > p
      flex: 0 1 100%
      padding: 10px 0px
    &.left-padding
      > p:first-of-type
        padding-left: 5%
        flex-basis: 95%

#sensor-status
  #machineLearningCompletion
    display: flex
    align-self: center

#sensor-status
  #locationInfo
    display: flex
    align-items: center

    &:hover
      opacity: .8
      cursor: pointer

    & p
      margin: 0 0 0 10px

/* fallback */
@font-face
  font-family: 'Material Icons'
  font-style: normal
  font-weight: 400
  src: local('Material Icons'), local('MaterialIcons-Regular'), url(../../general/fonts/icons.woff2) format('woff2')

.material-icons
  font-family: 'Material Icons'
  font-weight: normal
  font-style: normal
  font-size: 24px
  line-height: 1
  letter-spacing: normal
  text-transform: none
  display: inline-block
  white-space: nowrap
  word-wrap: normal
  direction: ltr
  -webkit-font-feature-settings: 'liga'
  -webkit-font-smoothing: antialiased
