@import '../../general/sass/utils/_variables'

#page-resellers-add
  width: 100%

  form
    display: flex
    flex-direction: column

    button
      min-height: 40px

    @media (max-width: 768px)
      width: 100%

      > div, > button
        width: 100%
        max-width: 100%
        margin: 0

  .add-integrations-text
    margin: 20px 0 10px 0

  .add-integrations-button
    @media (max-width: 768px)
      width: 100%
      max-width: 100%
