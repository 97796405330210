#page-login
  align-items: center
  bottom: 0
  display: flex
  justify-content: center
  min-height: 320px
  left: 0
  position: absolute
  right: 0
  top: 0

  form
    display: flex
    flex-wrap: wrap
    justify-content: center
    max-width: 300px
    padding: 20px 0

    @media (max-width: 768px)
      width: 100%
      max-width: 100%

      button
        width: 100%
        max-width: 100%

  img, label, button
    flex: 1 0 100%

  img
    border-radius: 5px
    margin-bottom: 20px
    max-height: 120px
    max-width: 120px

  label
    margin-bottom: 0px

  input
    width: 100%

  button
    margin-top: 25px
