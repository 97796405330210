@import '../general/sass/utils/_mixins'
@import '../general/sass/utils/_variables'

// Forms
.btn-actions
  margin-top: 25px
  width: 100%
  button
    margin-right: 25px
    &:last-child
      margin-right: 0

a.btn
  display: inline-block

button.unstyled, .btn.unstyled
  background: transparent
  border: 0
  cursor: pointer
  outline: 0
  &:hover
    transform: translateY(-2px)

button:not(.unstyled):not(.react-datepicker__navigation), .btn:not(.unstyled)
  border: 1px solid $green
  color: $dark-grey
  cursor: pointer
  font: 14px $helvetica
  height: 36px
  min-width: 200px
  padding-top: 3px
  position: relative

  &.search::before,
  &::after
    background: url(../general/img/loading.svg) no-repeat
    background-size: cover
    content: ''
    height: 24px
    margin: -12px 0 0 10px
    opacity: 0
    position: absolute
    top: 50%
    width: 24px
    +transition(.33s)

  &:disabled
    border-color: lighten($green, 25)
    color: $dark-grey
    cursor: not-allowed
    pointer-events: none

  &.loading::after
    opacity: 1

  &:focus,
  &:hover
    outline: 0

  &:not(.search):not(.edit):not(.add):not(.remove):not(.title-edit):not(.delete):not(.open-in-new):not(.renew):focus,
  &:not(.search):not(.edit):not(.add):not(.remove):not(.title-edit):not(.delete):not(.open-in-new):not(.renew):hover
    background: $green
    color: #fff

  &.cancel
    border-color: $red
    &:focus,
    &:hover
      background: $red !important
    &:disabled
      border-color: lighten($red, 25)

  &.search,
  &.title-edit
    border: 0
    min-width: auto
    position: absolute
    right: 0
    &::before,
    &::after
      background-size: contain

  &.search
    height: 28px
    margin-right: 0
    right: 2px
    top: 16px
    width: 28px

    &::before,
    &::after
      margin-left: -14px
    &::before
      background-image: url(../general/img/search.svg)
      opacity: 1
    &.loading::before
      opacity: 0

  &.title-edit
    height: 20px
    top: 0
    width: 20px
    &::after
      background-image: url(../general/img/edit.svg)
      height: 20px
      margin: -10px 0 0 -10px
      opacity: 1
      width: 20px

  &.add,
  &.delete,
  &.edit,
  &.open-in-new,
  &.remove,
  &.renew
    background: no-repeat
    background-size: 20px 20px
    border: 0
    height: 20px
    min-width: auto
    opacity: .6
    width: 20px
    &::after
      display: none
    &:focus,
    &:hover
      opacity: 1

  &.add
    background-image: url(../general/img/add-circle.svg)
    opacity: 0.8

  &.delete
    background-image: url(../general/img/delete.svg)

  &.edit
    background-image: url(../general/img/edit.svg)

  &.open-in-new
    background-image: url(../general/img/open-in-new.svg)
    vertical-align: top

  &.remove
    background-image: url(../general/img/remove-circle.svg)
    opacity: 0.8

  &.renew
    background-image: url(../general/img/renew.svg)
    opacity: 0.8
