@import '../general/sass/utils/_variables'

#devices-monitoring
  width: 100%
  > div > form
    margin-top: 25px
    justify-content: flex-start
    flex-wrap: nowrap
    > div
      padding-right: 20px
      max-width: 200px
      label
        margin-top: 0
    > div:nth-child(3)
      min-width: 175px

    > .search-button
      width: 100px

      @media (max-width: 768px)
        width: 100%

      button.search
        position: relative

    > .download-csv-button
      margin-left: auto
      margin-top: 12px
      padding-right: 0
      width: auto

      button
        min-width: 170px

      @media (max-width: 768px)
        display: none

    @media (max-width: 768px)
      flex-direction: column
      > div
        padding: 0
        max-width: 100% !important
        width: 100%
        > label
          margin: 0
      button.search
        position: relative
        margin-bottom: 30px
        margin-left: 50%

  .sensor-status-section
    display: flex
    justify-content: center
    align-items: center
    .sensor-status
      border-radius: 50%
      display: inline-block
      height: 16px
      margin: -2px 8px 0 0
      vertical-align: middle
      width: 16px
      &.CONNECTED
        background: $green
      &.DISCONNECTED
        background: $red
      &.NEVER_CONNECTED
        background: $dark-grey

  .MuiTableCell-body
    padding: 7px 0 !important

  .device-cell
    font-size: 12px

#account
  border: 1px solid $dark-grey
  padding: $page-padding
