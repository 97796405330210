// File to customize external components

/// React Table
.ReactTable
  font-size: 12px
  a
    font-size: 12px

  .-pagination
    .-previous, .-next
      button
        display: inline-block
    .-previous
      text-align: left
    .-next
      text-align: right

  .rt-thead
    padding: 5px 0

  .rt-thead .rt-th
    align-self: center

  .rt-th, .rt-td
    white-space: normal
    word-break: break-word

  .-loading.-active > div
    transform: translateY(-50%)
