#page-stock-edit
  width: 100%
  > p
    margin-bottom: 30px

  form
    > div:nth-child(2)
      h6
        border-bottom: 0
      button
        margin: 10px 0 40px
        max-width: 100%
        width: 380px

    &[data-step="2"] > button
      left: 53%
