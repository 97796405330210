@import '../../../general/sass/utils/_variables'

#page-stock-block
  form > h6
    border: 0
    margin-bottom: 5px

  p.error-message
    margin-top: 16px

  @media (max-width: 768px)
    div
      width: 100%

      > button
        width: 100%
        max-width: 100%

#page-stock-block-ids-step
  .formats-text,
  .errors
    width: 100%
  .formats-text
    p
      margin-right: 25px
  .errors
    margin-top: 25px
    p
      margin: 10px 0

#page-stock-step-confirm p
  margin-bottom: 15px

#page-stock-block-step-confirm h6, #page-stock-block-step-end h5
  border: 0

#page-stock-block-step-end h5
  color: $green
  font-family: $helvetica-medium
