#page-subscription-browse
  width: 100%
  form
    justify-content: flex-start
    > div
      padding-right: 20px
      max-width: 200px
      label
        margin-top: 0
    button.search
      position: relative

    @media (max-width: 768px)
      flex-direction: column
      > div
        padding: 0
        max-width: 100% !important
        width: 100%
        > label
          margin: 0
      button.search
        position: relative
        margin-bottom: 30px
        margin-left: 50%

  .ReactTable
    @media (max-width: 768px)
      max-width: calc(100vw - 30px)
      .rt-table
        overflow-x: auto
      .-pagination
        > div > button
          min-width: 0
