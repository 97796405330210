#page-stock-browse
  width: 100%
  form
    justify-content: flex-start
    margin-bottom: 10px
    .filters-wrapper
      max-width: 500px
      width: 50%
      label
        display: inline-block
        margin-bottom: 10px
        margin-right: 10px
        width: 200px
        margin-top: 0
        &:first-child
          margin-right: 20px
      > div:nth-child(1n+2) label
        margin-top: 0
      button.add,
      button.remove
        margin: 0 2px
        vertical-align: middle

      @media (max-width: 768px)
        flex-direction: column
        width: 100%
        max-width: 100%
        > div
          > label
            margin: 0
            max-width: 100% !important
            width: 100%
          button
            top: 7px

    > div:nth-child(2)
      align-self: flex-end
      max-width: 50px
      padding: 0 0 28px

      @media (max-width: 768px)
        max-width: 100%
        width: 100%
        padding: 0
        margin-bottom: 15px
        margin-left: 50%

    button.search
      position: relative

  a 
    font-size: 12px