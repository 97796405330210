@import '../../general/sass/utils/_mixins'
@import '../../general/sass/utils/_variables'

div.flex-container
  display: flex

div.input-select,
div.input-select-table
  span.placeholder
    display: block
    position: relative
    top: auto
  &.error span.placeholder
    color: $input-error

  label
    cursor: pointer
    display: inline-block
    font-size: 13px
    margin: 0 40px 0 0

  span.check
    background: $green
    bottom: 5px
    height: 14px
    left: 3px
    opacity: 0
    position: absolute
    width: 14px
    transform: scale(.5)
    +transition(.33s)

  input[type="checkbox"],
  input[type="radio"]
    appearance: none
    border: 1px solid $green
    cursor: pointer
    display: inline-block
    height: 20px
    margin: 0 10px 2px 0
    vertical-align: middle
    width: 20px
    &:hover:not(:disabled),
    &:checked
      ~ span.check
        opacity: 1
    &:checked
      ~ span.check
        transform: scale(1)
