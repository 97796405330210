@import '../general/sass/utils/_variables'

@keyframes dialog-animation 
  from 
    opacity: 0
  to 
    opacity: 1

.dialog-overlay
  background-color: rgba(0, 0, 0, 0.5)
  animation-name: dialog-animation 
  animation-duration: 0.2s

  .dialog-content
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px
    border-radius: 5px
    display: flex
    flex-direction: column

    p
      line-height: 1.4
      margin-bottom: 30px

    .btn-group
      display: flex
      align-self: flex-end

      @media (max-width: 768px)
        display: flex
        flex-direction: column
        width: 100%

        button
          min-width: 0px
          width: 100%

          &:first-child
            margin-bottom: 10px

    .error-message
      color: $input-error
      margin-bottom: 20px