#appliances-page
  h6
    margin-bottom: 10px
  
  hr.separator
    border: none
    border-top: 1px solid #000
    width: 100%
    margin: 0

    &.mt
      margin-top: 10px
    &.mr
      margin-right: 10px
    &.mb
      margin-bottom: 10px
    &.ml
      margin-left: 10px

  form > div
    min-width: 460px

  @media (max-width: 768px)
    form
      > div
        min-width: 100%
        div label
          width: 100%
      > div:nth-child(2)
        order: -1

  .input-select
    margin-top: 0
    max-width: none
    padding-top: 0

    label
      margin-right: 0
      width: 50%

      @media (max-width: 768px)
        width: 100%

  .btn-actions button
    max-width: 280px

  div.error-messages
    margin: 10px
