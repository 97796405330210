@import '../general/sass/utils/_variables'

.error-message
  color: $input-error
  display: block
  max-height: 100px
  overflow: auto
  flex: 1 0 100%
  font-size: 14px
  margin-bottom: 10px
  &.center
    text-align: center
