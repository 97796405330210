@import '../general/sass/utils/_variables'

p
  font-size: 14px
  margin-bottom: 5px
  &.small-size
    font-size: 13px
  &.no-spacing
    margin: 0
  &.bottom-spacing
    margin-top: 5px
  &.top-spacing
    margin-top: 5px
  &.bolder
    font-family: $helvetica-medium
  &.italic
    font-style: italic
  &.highlight
    color: $green
  &.highlight-link
    color: $dark-green
    transition: color 0.3s
    &:hover
      color: $green
  &.warning
    color: $dark-orange
  &.caution
    color: $red
  &.inline
    display: inline-block
    margin-left: 2px
    margin-right: 2px
    &:first-child
      margin-left: 0
    &:last-child
      margin-right: 0
