#page-country-browse
  width: 100%
  form
    justify-content: flex-start
    > div
      padding-right: 20px
      max-width: 200px
      label
        margin-top: 0
    button.search
      position: relative

    @media (max-width: 768px)
      flex-direction: column
      > div
        padding: 0
        max-width: 100% !important
        width: 100%
        > label
          margin: 0
      button.search
        position: relative
        margin-bottom: 30px
        margin-left: 50%

  .tariff-info
    h5,h6
      flex: 0 1 100%
      border: none
    h5
      margin-bottom: 20px
    h6
      margin-bottom: 10px
    > div
      display: flex
      flex: 0 1 100%
      & p
        padding: 4px 0px
        flex: 0 1 100%
      & p:first-of-type
        flex: 0 1 32%