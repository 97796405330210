@import '../../general/sass/utils/_mixins'
@import '../../general/sass/utils/_variables'

// Forms
form
  display: flex
  flex-wrap: wrap
  justify-content: space-between

  > p, > button
    flex: 1 1 100%

  > div
    width: 47%

  > button
    max-width: 200px

label, div.input-select
  display: block
  margin: 20px 0
  max-width: 400px
  padding-top: 12px
  position: relative
  span.placeholder
    font-size: 14px

div.input-select.nested-selects
  label
    display: block
  label:nth-of-type(2)
    margin-left: 32px

label
  span.placeholder
    background: #fff
    left: 6px
    padding: 2px
    pointer-events: none
    position: absolute
    top: 20px
    z-index: 1
    +transition(.33s)
    &.active
      color: $green

  span.placeholder.active,
  span.placeholder.deactive,
  &.input-file span.placeholder
    font-size: 12px
    top: 3px
  &.input-file span.placeholder
    top: -8px
  &.input-file.loading span.placeholder::after
    background: url(../../general/img/loading.svg)
    background-size: contain
    content: ''
    display: inline-block
    height: 16px
    margin: -1px 0 0 3px
    vertical-align: top
    width: 16px

  &.textarea
    padding-top: 15px
    span.border
      bottom: 2px
    span.placeholder.active,
    span.placeholder.deactive,
    &.input-file span.placeholder
      top: 7px

@keyframes onAutoFillStart
  0%
    background: #fff
  100%
    background: #fff
@keyframes onAutoFillCancel
  0%
    background: #fff
  100%
    background: #fff
input:-webkit-autofill
  animation-name: onAutoFillStart
  transition: background-color 50000s ease-in-out 0s

input:not(:-webkit-autofill)
  animation-name: onAutoFillCancel

input, select, textarea
  border: 1px solid $light-grey
  font: 14px $helvetica
  height: 36px
  padding: 0 8px 0
  width: 100%
  +transition(.33s)

  &[name="production_date"]
    &:focus
      border-color: $green
  &.error
    border-color: $input-error

  &.error ~ span
    background: $input-error

  &:disabled
    opacity: .4

  &:focus,
  &.error
    outline: 0
    ~ span
      width: 100%

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration
  -webkit-appearance: none

input[type="search"]
  padding-right: 30px

input[type="file"]
  padding-top: 8px

select
  appearance: none
  border-radius: 0

textarea
  height: 66px
  line-height: 1.5em
  min-height: 30px
  padding-top: 8px
  resize: vertical
