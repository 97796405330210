#page-resellers-summary
  width: 100%

  form
    justify-content: flex-start
    > div
      padding-right: 20px
      max-width: 200px
      label
        margin-top: 0
    button.search
      position: relative

    @media (max-width: 768px)
      flex-direction: column
      > div
        padding: 0
        max-width: 100% !important
        width: 100%
        > label
          margin: 0
      button.search
        position: relative
        margin-bottom: 30px
        margin-left: 50%

  .expandable-section
    padding: 20px

    h6
      flex: 0 1 100%
      border: none
      margin-bottom: 4px

    *
      color: #777

  .material-icons
    font-family: 'Material Icons'
    font-weight: normal
    font-style: normal
    font-size: 20px !important
    line-height: 1
    letter-spacing: normal
    text-transform: none
    display: inline-block
    white-space: nowrap
    word-wrap: normal
    direction: ltr
    -webkit-font-feature-settings: 'liga'
    -webkit-font-smoothing: antialiased
    display: flex
    align-items: center
    justify-content: center
    margin-left: 5px
    margin-right: -12px
