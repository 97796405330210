#admin-tools
  justify-content: flex-start !important

  .col
    max-width: 280px

  ul
    list-style: none

    @media (max-width: 768px)
      > li
        margin-bottom: 10px
