@import '../../general/sass/utils/_variables'

#new-report-generation-status-page
  p
    margin-bottom: 15px

  div .ReactTable
    .-loading img
      width: 25px
    .-pageInfo
      display: none
    a > span
      display: block
      font-size: 12px
    @media (max-width: 768px)
      max-width: calc(100vw - 90px)
      .rt-table
        overflow-x: auto
      .-pagination
        > div > button
          min-width: 0
