@import '../general/sass/utils/_variables'

h1, h2, h3, h4, h5, h6
  border-bottom: 1px solid $green
  margin-bottom: 25px
  padding-bottom: 5px
  position: relative

  span.highlight
    color: $green
    font-family: $helvetica-medium
    font-size: 1.1em

h1
  font-size: 36px

h2
  font-size: 32px

h3
  font-size: 28px

h4
  font-size: 24px

h5
  font-size: 20px

h6
  font-size: 16px
