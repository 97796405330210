#page-stock-add
  width: 100%
  > p
    margin-bottom: 30px

  form
    > div:nth-child(2)
      h6
        border-bottom: 0
      button
        margin: 10px 0 40px
        max-width: 100%
        width: 380px

    @media (max-width: 768px)
      > div, > button
        width: 100%
        max-width: 100%
        margin: 0

      #file-upload-section
        > h6
          margin: 10px 0

        > button
          margin: 0
          width: 100%

      > button
        left: 0 !important

    &[data-step="2"] > button
      left: 53%
