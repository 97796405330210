@import '../../general/sass/utils/_variables'

#admintools-emailreport-create-send
  form > div
    margin-top: 15px
    width: 100%
  .report-link a
    color: $dark-green
    font: 14px $helvetica-medium
    margin-left: 2px
    &:hover
      color: $green
  .input-select
    margin-top: 0
