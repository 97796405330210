@import '../../general/sass/utils/_variables'

#sensor-details
  display: flex
  flex-wrap: wrap
  justify-content: flex-start
  margin: 40px 0 30px 0

  > div
    padding: 0 20px

    &:first-child
      padding-left: 0

    & p
      &.online
        color: $dark-green
      &.offline
        color: $red

    @media (max-width: 768px)
      margin: 10px 0
      flex-direction: column
      > div
        padding: 5px 0
