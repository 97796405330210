@import '../../general/sass/utils/_variables'
@import '../../general/sass/utils/_mixins'

.rc-time-picker
  input
    border-radius: 0
    color: #333
    font: 14px $helvetica
    height: 36px

  .rc-time-picker-clear
    top: 8px
