#page-disaggregation-monitor
  width: 100%
  form
    margin-top: 25px
    justify-content: flex-start
    flex-wrap: nowrap
    > div
      padding-right: 20px
      max-width: 200px
      label
        margin-top: 0
    button.search
      position: relative

    @media (max-width: 768px)
      flex-direction: column
      > div
        padding: 0
        max-width: 100% !important
        width: 100%
        > label
          margin: 0
      button.search
        position: relative
        margin-bottom: 30px
        margin-left: 50%

  .expandable-section
    padding: 20px

    h6
      flex: 0 1 100%
      border: none
      margin-bottom: 4px

    *
      color: #777