#page-provider-browse
  width: 100%

  div > form
    > div
      max-width: 200px
      > label
        margin-top: 0

    @media (max-width: 768px)
      flex-direction: column
      > div
        padding: 0
        max-width: 100% !important
        width: 100%
      button.search
        position: relative
        margin-bottom: 30px
        margin-left: 50%

  .material-icons
    cursor: pointer
    color: #626262 !important
