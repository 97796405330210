@import '../../general/sass/utils/_variables'

label
  .react-datepicker-wrapper,
  .react-datepicker-wrapper > div
    display: block
  .react-datepicker-popper
    z-index: 2
  &.error input
    border-color: $input-error
  .react-datepicker__month-wrapper
    min-width: 210px
    >.react-datepicker__month-text
      padding: 10px 0
  .react-datepicker-year-header
    min-height: 23px
    @media (max-width: 768px)
      display: flex
      justify-content: center
      align-items: center
      min-height: 35px
