@import '../../general/sass/utils/_variables'

#admintools-eventsexporting
  form > div
    width: 100%

  p.bolder
    margin-bottom: 15px

  label
    max-width: 280px
    &.has-error input
      border-color: $red

  .react-datepicker-wrapper,
  .react-datepicker__input-container
    display: block

#admintools-eventsexportinghistory .ReactTable a
  color: $green
  font-size: 12px
  &:hover
    color: $dark-green

#admintools-eventsexportinghistory div.ReactTable
  @media (max-width: 768px)
    max-width: calc(100vw - 90px)
    .rt-table
      overflow-x: auto
    .-pagination
      > div > button
        min-width: 0
