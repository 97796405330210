@import '../general/sass/utils/_mixins'
@import '../general/sass/utils/_variables'

header
  background: #fff
  border-bottom: 1px solid $green
  display: flex
  justify-content: space-between
  position: fixed
  left: 0
  right: 0
  top: 0
  margin: 0 15px
  z-index: 10
  @media (max-width: 768px)
    margin: 0

  #logo-button
    max-height: 100%
    padding: 0 15px
    @media (max-width: 768px)
      padding: 0
    a
      display: inline-block
      width: 150px
      img
        max-height: 100%

      &:focus
        outline: 0

  #menu-button
    cursor: pointer
    display: flex
    align-items: center
    padding: 0 20px
    @media (min-width: 768px)
      display: none
