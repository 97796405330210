@import '../general/sass/utils/_variables'

#sensor-wifi-strength
  display: flex
  flex-direction: row

  div
    border-radius: 8px
    height: 16px
    margin: 3px
    width: 16px

    &:first-child
      margin-left: 0

    &.green
      background-color: $green

    &.grey
      background-color: $light-grey
